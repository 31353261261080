import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_OurWorkHero = _resolveComponent("OurWorkHero")
  const _component_FindOutAboutUS = _resolveComponent("FindOutAboutUS")

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode(_component_OurWorkHero),
    _createVNode(_component_FindOutAboutUS)
  ], 64))
}