
import { Options, Vue } from 'vue-class-component';
import OurWorkHero from '@/components/OurWorkHero.vue';
import FindOutAboutUS from '@/components/FindOutAboutUS.vue';

@Options({
  components: {
    OurWorkHero,
    FindOutAboutUS,
  },
})
export default class Work extends Vue {}
